.text__right {
    text-align: right;
}

.table__header{
    background-color: #89c3f3;

}

.editable__feild{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: aliceblue;
}
.editable__feild:hover {
    /* border-color: #007bff; */
    box-shadow: 0 2px 5px rgba(0, 123, 255, 0.5);
  }
.editable__feild input{
    height: 10px;
}

/* For detail scedule tab */
.detail__tr th{
    min-width: 100px;
    background-color: #89c3f3
}

.client__custom__tr th{
    min-width: 110px;
    background-color: #89c3f3
}

.client__custom__tr .description__cell{
    min-width: 300px
}